<template>
  <div class="setting world">
    <div class="title backgroundWhite marB10">
      <h1 class="h1">修改密码</h1>
    </div>
    <div class="gray"></div>
    <div class="setting_content backgroundWhite paddT20 paddB20">
      <div class="content paddT20 paddB20">
        <div class="box paddT20 paddB20">
          <el-form
            ref="form"
            :model="form"
            :rules="err"
            label-width="200px"
            class="form paddT20 paddB20"
          >
            <!-- <el-form-item label="原密码：" class="w350">
              <el-input
                placeholder="请输入原登录密码"
                v-model="form.oldPass"
                class="w350"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="新密码：" prop="newPass">
              <el-input
                placeholder="设置登录新密码（8-16位数字字母组合）"
                type="password"
                v-model="form.newPass"
                class="w350 password"
                @focus="passHandle"
              ></el-input>
              <!-- <div class="flex littlogo" v-if="isPass">
                <img src="@/static/login/littlelogo.png" alt="" />
                <p>
                  您的密码必须包含至少6~18个字符和字母数字组合
                  尽量避免使用生日等易于被他人猜到的密码
                </p>
              </div> -->
            </el-form-item>
            <el-form-item label="确认新密码：" prop="reNewPass">
              <el-input
                placeholder="请确认新密码"
                type="password"
                v-model="form.reNewPass"
                class="w350"
              ></el-input>
            </el-form-item>
            <el-form-item class="marT50">
              <el-button
                type="primary"
                class="bs_btn_oo w350"
                @click="modifyPassword('form')"
                >确认修改</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: "settingPass",
  data() {
    return {
      form: {
        // oldPass: "",
        newPass: "",
        reNewPass: "",
      },
      err: {
        newPass: [
          { required: true, message: "您设置的密码有误！", trigger: "blur" },
          // {
          //   pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
          //   message:
          //     "密码必须为8位以上，且必须包含数字和字母",
          //   trigger: "blur",
          // },
        ],
        reNewPass: [
          { required: true, message: "您设置的密码有误！", trigger: "blur" },
          // {
          //   pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
          //   message:
          //     "密码必须为8位以上，且必须包含数字和字母",
          //   trigger: "blur",
          // },
        ],
      },
      isPass: false,
    };
  },
  computed:{
    ...mapState(["userPassword"])
  },
  methods: {
    modifyPassword() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.newPass != this.form.reNewPass) {
            this.$message({
              message: "两次密码不一致，请重新输入",
              center: true,
              type: "warning",
            });
            this.form.reNewPass = "";
          } else {
            console.log(valid,55555);
            let params = {
              // oldPwd: this.form.oldPass,
              newPwd: this.$sm2Encrypt(this.form.newPass),
            };
            let that = this;

            that.$api.updateUserPwd(params).then(function(res) {
              if (!res.data.success) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message({
                  message: "密码修改成功",
                  center: true,
                  type: "success",
                });
                that.form = {};
                this.userPassword = params.newPass;
              }
            });
          }
        }
      });
    },
    onSubmit() {},
    passHandle() {
      this.isPass = true;
    },
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/setting.less";

.passinput {
  position: relative;
}
.littlogo {
  display: flex;
  position: absolute;
  left: 420px;
  width: 75%;
  top: 5px;
  z-index: 555;
  background: #ffffff;
  padding: 5px;

  img {
    width: 14px;
    height: 14px;
    display: block;
    line-height: 36px;
    margin-right: 6px;
    margin-top: 3px;
  }

  p {
    padding: 0px;
    line-height: 20px;
    font-size: 12px;
  }
}
.paddT20 {
  padding-top: 13px;
  padding-bottom: 13px;
}
.world {
  background: #ffffff;
  margin: 0px;
}
</style>
